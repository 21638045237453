import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import { ConfigProvider } from 'antd';
import locale from 'antd/lib/locale-provider/zh_CN';

import logger from 'logger.config';
import lito, { setupApp, setupLogging } from 'lito.config';
import growthbook from 'growthbook.config';
import { TestFeature } from 'growthbook.features';
import { APP_NAME } from 'services/constants';
import { LocalStorageCache } from 'services/cache';

import App from './App';
import { rootReducer, initialRootState } from './reducer';
import { StateProvider } from './hooks/useGlobalState';

const LOGGER = logger.extend('main');
const testFeature = growthbook.getConfiguration(TestFeature);

moment.locale('zh-cn');

setupApp();
setupLogging();

ReactDOM.render(
  <ConfigProvider locale={locale}>
    <StateProvider initialState={initialRootState} reducer={rootReducer}>
      <App />
    </StateProvider>
  </ConfigProvider>,
  document.getElementById('root'),
);

testFeature.effect(async () => {
  const { redirect, reload } = testFeature.get();

  if (redirect && !window.location.href.startsWith(redirect)) {
    LOGGER.warn('即将重定向到:', redirect);

    await lito.shutdown();

    window.location.href = `${redirect}${window.location.search}${window.location.hash}`;

    return;
  }

  if (reload) {
    const cache = new LocalStorageCache(`${APP_NAME}/reload`);

    if (cache.get() === reload) {
      LOGGER.warn('完成刷新:', reload);

      return;
    }

    cache.set(reload);

    LOGGER.warn('即将刷新:', reload);

    await lito.shutdown();

    window.location.reload();
  }
});

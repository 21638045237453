import { applyMiddleware, compose, StoreEnhancer, Middleware } from 'redux';
import thunk from 'redux-thunk';

import errorHandle from './middlewares/errorHandle';

const middlewares: Middleware[] = [thunk, errorHandle];
const enhancers: StoreEnhancer[] = [];

if (process.env.NODE_ENV !== 'production') {
  if (typeof window.__REDUX_DEVTOOLS_EXTENSION__ === 'function') {
    enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
  }
}

const enhancer: StoreEnhancer = compose(applyMiddleware(...middlewares), ...enhancers);

export default enhancer;

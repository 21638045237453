import { Lito, LitoLabels, Timer, VectorCollector } from '@leyan/lito';
import { loggerManager, type LoggerMeta } from '@leyan/logger';
import { isOk, tql } from '@leyan/tql';
import Axios from 'axios';
import growthbook from 'growthbook.config';

import { deviceid, sessionid } from 'device';
import logger from 'logger.config';
import { LoggingFeature } from 'growthbook.features';

const LOGGER = logger.extend('lito.config');

const loggingFeature = growthbook.getConfiguration(LoggingFeature);

function BrowserIntegration(lito: Lito) {
  const instance = lito as Lito<{
    // eslint-disable-next-line camelcase
    app_up: {
      screen: `${number}x${number}`;
      window: `${number}x${number}`;
      referer: string;
    };
    // eslint-disable-next-line camelcase
    app_error: {
      type: string;
      message: string;
    };
  }>;

  return () => {
    instance.capture({
      name: 'app_up',
      labels: {
        screen: `${window.screen.width}x${window.screen.height}`,
        window: `${window.innerWidth}x${window.innerHeight}`,
        referer: window.location.href,
      },
    });

    window.addEventListener('error', (event) => {
      instance.capture({
        name: 'app_error',
        labels: {
          type: event.type,
          message: event.message,
        },
      });
    });

    window.addEventListener('unhandledrejection', (event) => {
      instance.capture({
        name: 'app_error',
        labels: {
          type: event.type,
          message: event.reason?.message || String(event.reason),
        },
      });
    });

    window.addEventListener('beforeunload', () => {
      instance.flush();
    });
  };
}

function LoggingIntegration(
  lito: Lito,
  filter: false | ((meta: LoggerMeta) => boolean | 'lazy') = false,
) {
  const instance = lito as Lito<{
    logging: {
      name: string;
      level: string;
      message: string;
    };
  }>;

  return () => {
    loggerManager.onLogging(async (meta) => {
      const result = filter ? filter(meta) : false;

      if (result) {
        const { name, level, message, params, labels } = meta;

        instance.capture(
          {
            name: 'logging',
            labels: {
              ...labels,
              ...params.reduce<Record<string, string>>((result, param, index) => {
                let value;

                try {
                  value = JSON.stringify(param);
                } catch {
                  value = String(param);
                }

                // eslint-disable-next-line no-param-reassign
                result[`params_$${index}`] = value;

                return result;
              }, {}),
              name,
              level,
              message,
            },
          },
          result === 'lazy',
        );
      }
    });
  };
}

function UserIntegration<T extends LitoLabels>(lito: Lito) {
  const instance = lito as Lito<{
    // eslint-disable-next-line camelcase
    user_up: {};
  }>;

  const timer = new Timer().start();

  return (user: T) => {
    const duration = timer.stop().duration();

    instance
      .setLabels((prev) => {
        return {
          ...prev,
          ...user,
        };
      })
      .capture({
        name: 'user_up',
        value: duration,
      });
  };
}

const lito = new Lito();

lito.init({
  appname: process.env.REACT_APP_LITO_APPNAME,
  appkey: process.env.REACT_APP_LITO_APPKEY,
  version: process.env.REACT_APP_VERSION,
  deviceid,
  sessionid,
  collect: VectorCollector(
    Axios.create({
      baseURL: process.env.REACT_APP_LITO_API_ENDPOINT,
      headers: {
        'Content-Type': 'text/plain',
      },
    }),
  ),
  onCollect(events) {
    LOGGER.debug('上报事件 x%s', events.length);
  },
  onDiscard(events) {
    LOGGER.warn('丢弃事件 x%s', events.length);
  },
  onError(error, events) {
    LOGGER.error('上报事件 x%s 错误:', events.length, error);
  },
});

export const setupApp = BrowserIntegration(lito);

export const setupLogging = LoggingIntegration(lito, (meta) => {
  const { enable, expression } = loggingFeature.get();

  if (!enable) {
    return false;
  }

  if (expression) {
    const result = tql(meta, expression);

    if (!isOk(result) || !result.value) {
      return false;
    }
  }

  if (meta.name === LOGGER.name) {
    if (lito.isAlive()) {
      return 'lazy';
    }

    return false;
  }

  return true;
});

export const setupUser = UserIntegration<{
  // eslint-disable-next-line camelcase
  vender_id: string;
  // eslint-disable-next-line camelcase
  assistant_id: string;
  // eslint-disable-next-line camelcase
  dongdong_version?: string;
}>(lito);

export default lito;

import { AxiosRequestConfig } from 'axios';

import { WEB_TOKEN } from 'CONST';

const tokenHandler = (config: AxiosRequestConfig) => {
  const token = localStorage.getItem(WEB_TOKEN);

  if (token && config.headers) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
};

export default tokenHandler;

import { createDeviceid, createSessionid } from '@leyan/lito';

function getStorageId(storage: Storage, key: string, fallback: () => string) {
  let id = storage.getItem(key);

  if (!id) {
    id = fallback();

    storage.setItem(key, id);
  }

  return id;
}

export const deviceid = getStorageId(localStorage, 'deviceid', () => createDeviceid());

export const sessionid = getStorageId(sessionStorage, 'sessionid', () => createSessionid());

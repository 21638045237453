import { authReducer, initialAuthState } from './auth';
import { hukouAuthReducer, initialHukouAuthState } from './hukouAuth';
import { shopReducer, initialShopState } from './shop';
// eslint-disable-next-line
export const rootReducer: IReducer = ({ auth, hukouAuth, shop }, action) => {
  return {
    auth: authReducer(auth, action),
    hukouAuth: hukouAuthReducer(hukouAuth, action),
    shop: shopReducer(shop, action),
  };
};
export const initialRootState = {
  ...initialAuthState,
  ...initialHukouAuthState,
  ...initialShopState,
};

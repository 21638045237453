/* eslint-disable import/prefer-default-export */
/* eslint-disable camelcase */
import Axios, { AxiosResponse } from 'axios';

import { AUTH_TAG, HUKOU_AUTH_TAG, WEB_TOKEN } from 'CONST';
import errorResponseHandler from './errorhandler';
import codeHandler from './codeHandler';
import tokenHandler from './tokenHandler';

export const jdClient = Axios.create({
  baseURL: process.env.REACT_APP_JD_API,
  headers: {
    Authorization: localStorage.getItem(AUTH_TAG),
    'Content-Type': 'application/json',
  },
});
export const ecpImageJdClient = Axios.create({
  baseURL: process.env.REACT_APP_ECPIMAGEJD_API,
  headers: {
    Authorization: localStorage.getItem(WEB_TOKEN),
    'Content-Type': 'application/json',
  },
});
export const internalClient = Axios.create({
  baseURL: process.env.REACT_APP_JD_API,
  headers: {
    Authorization: `${localStorage.getItem(HUKOU_AUTH_TAG)}`,
    'Content-Type': 'application/json',
  },
});

// nta flagr/ jd-console
export const flagrClient = Axios.create({
  baseURL: process.env.REACT_APP_FLAGR_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const fredClient = Axios.create({
  baseURL: process.env.REACT_APP_FRED_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 10000,
});

export const storeHomeClient = Axios.create({
  baseURL: process.env.REACT_APP_STORE_HOME_ENDPOINT,
  headers: {
    Authorization: `Bearer ${localStorage.getItem(WEB_TOKEN)}`,
    'Content-Type': 'application/json',
  },
  timeout: 10000,
});

export const JDClient = Axios.create({
  baseURL: process.env.REACT_APP_JD_ENDPOINT,
  headers: {
    Authorization: localStorage.getItem(WEB_TOKEN),
    'Content-Type': 'application/json',
  },
});

export const itemClient = Axios.create({
  baseURL: process.env.REACT_APP_ECP_ITEM_API_ENDPOINT,
  headers: {
    Authorization: `Bearer ${localStorage.getItem(WEB_TOKEN)}`,
    'Content-Type': 'application/json',
  },
});

export const fansHomeClient = Axios.create({
  baseURL: process.env.REACT_APP_FANS_HOME_ENDPOINT,
  headers: {
    Authorization: `Bearer ${localStorage.getItem(WEB_TOKEN)}`,
    'Content-Type': 'application/json',
  },
  timeout: 10000,
});

export const marketingHomeClient = Axios.create({
  baseURL: process.env.REACT_APP_MARKETING_HOME_ENDPOINT,
  headers: {
    Authorization: `Bearer ${localStorage.getItem(WEB_TOKEN)}`,
    'Content-Type': 'application/json',
  },
  timeout: 10000,
});

export type Res<T> = {
  data: T;
  msg?: string;
  sub_code?: number;
};

export function resolve<T>(resource: Promise<AxiosResponse<Res<T>>>) {
  return resource.then((response) => {
    return response.data.data;
  });
}

export function resolveResponseData<T>(resource: Promise<AxiosResponse<T>>) {
  return resource.then((response) => {
    return response.data;
  });
}

jdClient.interceptors.response.use(codeHandler, errorResponseHandler);
ecpImageJdClient.interceptors.response.use(codeHandler, errorResponseHandler);
internalClient.interceptors.response.use(codeHandler, errorResponseHandler);

ecpImageJdClient.interceptors.request.use((config) => {
  const token = localStorage.getItem(WEB_TOKEN);

  if (token && config.headers) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = token;
  }

  return config;
});
storeHomeClient.interceptors.request.use(tokenHandler);
itemClient.interceptors.request.use(tokenHandler);
fansHomeClient.interceptors.request.use(tokenHandler);
marketingHomeClient.interceptors.request.use(tokenHandler);

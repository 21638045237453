import { DebugLogger, loggerManager } from '@leyan/logger';

const rootLogger = new DebugLogger('doggy');

if (process.env.NODE_ENV !== 'production') {
  rootLogger.enable();
}

loggerManager.setLogger(rootLogger);

const logger = loggerManager.getLogger('@');

export default logger;

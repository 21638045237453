import history from 'services/history';

function reset(message?: string) {
  const {
    location: { pathname },
  } = history;
  if (pathname.startsWith('/hukou')) {
    history.push('/hukou/reset');
    return;
  }
  history.push(`/auth/${message ? `error/${message}` : 'reset'}`);
}

export default reset;

import { createHashHistory } from 'history';
import ReactGA from 'react-ga';

const TRACK_ID = process.env.REACT_APP_GA_TRACK_ID as string;
const history = createHashHistory();
if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize(TRACK_ID);
  history.listen((location) => {
    ReactGA.pageview(location.pathname + location.search);
  });
}
export default history;

import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { decode } from 'jsonwebtoken';
import { Router, Switch, Redirect, Route, RouteComponentProps } from 'react-router-dom';
import { Provider } from 'react-redux';

import { Layout, Row, Col, Avatar, Dropdown, Menu, message, Spin } from 'antd';
import Icon from '@ant-design/icons';
import { fetchSettings } from 'api/settings';
import { ReactComponent as LogoSvg } from 'resources/logo.svg';
import store from 'services/store';
import { useGlobalState } from 'hooks/useGlobalState';
import history from 'services/history';
import { ProtectedRoute, MenuUseRouter } from './components';
import { READ_AUTH_TOKEN } from './reducer/auth';
import { UPDATE_INFO } from './reducer/shop';
import routes from './routes';
import 'antd/dist/antd.css';
import './global.css';
import styles from './App.module.css';

const { Sider, Header } = Layout;
const renderRoute = (route: any, key: string) => {
  if (route.path) {
    return (
      <ProtectedRoute
        key={key}
        auther={route.auther}
        path={route.path}
        exact={route.exact}
        component={route.component}
      />
    );
  }
  const items = route.items || [];
  return items.map((itemRoute: any, itemIndex: number) =>
    renderRoute(itemRoute, `${key}_${itemIndex}`),
  );
};
const App: FunctionComponent = () => {
  const [{ auth, shop }, dispatch] = useGlobalState();
  const [loading, setLoading] = useState(false);
  useEffect(() => dispatch({ type: READ_AUTH_TOKEN }), [dispatch]);
  const accountInfo = useMemo(() => {
    if (auth && auth.token) {
      return decode(auth.token) as any;
    }
    return null;
  }, [auth]);
  useEffect(() => {
    if (auth && auth.token) {
      setLoading(true);
      fetchSettings()
        .then((resp) => {
          dispatch({ type: UPDATE_INFO, payload: resp.data });
        })
        .catch((err) => {
          message.error(`获取店铺信息失败：${err.message}`);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [auth, dispatch]);
  const { isDirectShop } = shop;
  const safeRoutes = useMemo(() => {
    return routes.filter((route) => {
      if ((loading || isDirectShop) && route.key === 'settings') {
        return false;
      }
      return true;
    });
  }, [isDirectShop, loading]);
  if (loading) {
    return <Spin wrapperClassName={styles.spin} spinning={loading} />;
  }
  return (
    <Provider store={store}>
      <Router history={history}>
        <Route
          render={(routeProps) => {
            const {
              location: { pathname },
              history: { push },
            } = routeProps;
            const isDong = pathname.indexOf('/dong') > -1;
            const isHukou = pathname.indexOf('/hukou') > -1;
            const useLayout = !isDong && !isHukou && accountInfo;
            const handleMenuClick = (evt: any) => {
              const { key } = evt;
              if (key === 'switch') {
                push('/auth/reset');
              }
            };
            return (
              <Layout className="root-layout">
                {useLayout && (
                  <Sider trigger={null}>
                    <Header className={styles['logo-header']}>
                      <div className={styles.logo}>
                        <LogoSvg />
                      </div>
                    </Header>
                    <Route
                      render={(props: RouteComponentProps) => (
                        <MenuUseRouter
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...props}
                          routes={safeRoutes}
                        />
                      )}
                    />
                  </Sider>
                )}
                <Layout>
                  {useLayout && (
                    <Header className={styles.header}>
                      <Row justify="end">
                        <Col className={styles.profile}>
                          <Dropdown
                            trigger={['click']}
                            overlay={
                              <Menu onClick={handleMenuClick}>
                                <Menu.Item key="switch">切换账号</Menu.Item>
                              </Menu>
                            }
                          >
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a>
                              <Avatar icon="user" />
                              {accountInfo!.shopName}
                              <Icon className={styles.ml8} type="down" />
                            </a>
                          </Dropdown>
                        </Col>
                      </Row>
                    </Header>
                  )}
                  <Switch>
                    {safeRoutes.map((route, index) => renderRoute(route, `${index}`))}
                    <Redirect to="/qa" />
                  </Switch>
                </Layout>
              </Layout>
            );
          }}
        />
      </Router>
    </Provider>
  );
};

export default App;

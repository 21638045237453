export const UPDATE_INFO = 'UPDATE_INFO';
export interface Shop {
  isDirectShop: boolean | null;
}
export const initialShopState: { shop: Shop } = {
  shop: { isDirectShop: null },
};
export const shopReducer: IReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_INFO:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default shopReducer;

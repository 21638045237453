export const AUTH_TAG = 'token';
export const HUKOU_AUTH_TAG = 'hukouToken';
export const WEB_TOKEN = 'webToken';
const REACT_APP_JD_DONGDONG_PLUGIN_DEBUG_PIN_WHITE_LIST =
  process.env.REACT_APP_JD_DONGDONG_PLUGIN_DEBUG_PIN_WHITE_LIST || '';
// eslint-disable-next-line max-len
export const JD_DONGDONG_PLUGIN_DEBUG_PIN_WHITE_LIST: string[] =
  REACT_APP_JD_DONGDONG_PLUGIN_DEBUG_PIN_WHITE_LIST.split(',');
export const CMS_URL = `https://open-oauth.jd.com/oauth2/to_login?app_key=612D828330A0357D432B05E93CE70DD8&response_type=code&redirect_uri=${encodeURIComponent(
  'https://nta-capi.leyanbot.com/zenus/v1/auth/jingdong/callback',
)}&state=leyan_merchant${+new Date()}&scope=snsapi_base`;

export const HUB_URL = 'https://hub.leyantech.com';

import type { LoggerMeta } from '@leyan/logger';
import type { Expression } from '@leyan/tql';
import { defineFeature } from '@leyan/growthbook';

export type LoggingFeatureValue = {
  /**
   * 是否启用
   */
  enable?: boolean;
  /**
   * 上报规则
   */
  expression?: Expression<LoggerMeta>;
};

/**
 * 日志上报
 *
 * @see https://pre-growthbook-web.infra.leyantech.com/features/doggy-logging
 */
export const LoggingFeature = defineFeature({
  id: 'doggy-logging',
  default: {
    enable: false,
  } as LoggingFeatureValue,
});

export type TestFeatureValue = {
  /**
   * 重定向地址
   */
  redirect?: string;
  /**
   * 刷新标识
   */
  reload?: string;
};

/**
 * 测试配置
 *
 * @see https://pre-growthbook-web.infra.leyantech.com/features/doggy-test
 */
export const TestFeature = defineFeature({
  id: 'doggy-test',
  default: {} as TestFeatureValue,
});

import { FetchEvaluator, Growthbook } from '@leyan/growthbook';

import { deviceid } from 'device';
import logger from 'logger.config';

const LOGGER = logger.extend('growthbook.config');

export type AppAttributes = {
  venderId?: string;
  assistantId?: string;
  imVersion?: string;
};

const growthbook = new Growthbook<AppAttributes>();

growthbook.init({
  appName: process.env.REACT_APP_GROWTHBOOK_APPNAME,
  appKey: process.env.REACT_APP_GROWTHBOOK_APPKEY,
  appVersion: process.env.REACT_APP_VERSION,
  deviceId: deviceid,
  evaluate: FetchEvaluator({ endpoint: process.env.REACT_APP_GROWTHBOOK_API_ENDPOINT }),
  onEvaluation(config, result) {
    if (result) {
      LOGGER.info('特性评估变化参数:', config);
      LOGGER.info('特性评估变化结果:', result);

      return;
    }

    LOGGER.debug('特性评估参数:', config);
    LOGGER.debug('特性评估结果:', result);
  },
});

export default growthbook;
